<template>
  <div class="mine bg-blue-grey-10">
    <!-- top -->
    <div class="q-pa-sm bg-blue-grey-10 ">
      <!-- 头像部分 -->
      <div v-if="userInfo" class="bg-grey-10 height_80 rtl row items-center justify-between q-px-md top_head">
        <div class="row">
          <div class="height_50 width_50">
            <q-img :src="userInfo.headimgurl" class="full-height border-r-50p" />
          </div>
          <div class="text-deep-orange-3 q-mr-sm">
            <div>{{userInfo.nickname}}</div>
            <div>No: {{userInfo.id}}</div>
          </div>
        </div>
        <div>
          <q-btn class="glossy Ukij" rounded color="deep-orange" label="ئالىي ئەزا بولىمەن" to="/member" />
        </div>
      </div>
      <div class="bg-amber-7 text-white rtl q-py-sm q-px-md Ukij top_bottom">ئالىي ئەزالىق ۋاقتىڭىز يەنە <span
          class="text-deep-orange font_size18 fontW-900"> {{ vipTime }} </span> كۈن بار</div>

      <!-- 选项部分 -->
      <div class="bg-blue-grey-10 rtl q-py-md top_bottom row ">
        <div class="width_25p column items-center" v-for="item in head_select" :key="item.id"
          @click="onClickHead_select(item)">
          <div class="width_40 height_40 q-mb-sm"><q-img class="full-width full-height" :src="item.icon" /></div>
          <div class="Ukij font_size14 text-grey-2">{{item.name}}</div>
        </div>
      </div>
    </div>

    <!-- 浏览记录 record -->
    <div class="rtl bg-blue-grey-10 q-px-sm q-pb-sm">
      <!-- 标题 -->
      <div class="q-px-sm q-pt-sm bg-blue-grey-10 rtl row justify-between items-center top_head">
        <div class="row" style="height: 25px">
          <div class="bg-main-color title_right full-height q-ml-sm"></div>
          <div class="Ukij text-grey-2">يېقىندا كۆرگەنلىرىم</div>
        </div>
        <div class="row" @click="onClickMore(6)">
          <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
        </div>
      </div>

      <!-- 骨架 -->
      <div v-if="movieDate" class="rtl q-px-sm q-my-sm row full-width justify-between">
        <div v-for="item in 3" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <!-- 预览电影 -->
      <div v-if="!movieDate" class="bg-blue-grey-10 top_bottom q-pa-md row">
        <div class="column items-center width_30p q-mb-sm box-list" v-for="item in record" :key="item.id"
          @click="onClickMovie(item, 5)">
          <div class="height_150 full-width relative-position border-r-8 overflow-h">
            <q-img :src="item.movie.image_url ? item.movie.image_url : ''" class="full-width full-height border-r-10" />
            <div v-if="item.movie.is_free == 1"
              class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
              style="border-radius: 0 0 0 15px;">
              <div class="e-ml-5 fontW-900">VIP</div>
            </div>
            <div v-if="item.movie.is_free == 0"
              class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
              style="border-radius: 0 0 0 15px;">
              <div class="e-ml-5 Ukij">ھەقسىز</div>
            </div>
            <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size12">
              <div class="e-ml-5 row align-center">
                <div class="e-mx-3">{{ item.movie.score }}</div>
                <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
              </div>
            </div>
          </div>
          <div class="Ukij font_size14 text-grey-2 text-center text-ellipsis">{{ item.movie.name}}</div>
        </div>

        <!-- 没有预览内容时 -->
        <div v-if="record.length == 0" class="Ukij Dflex justify-center bg-white full-width q-py-lg text-grey-7">تېخى
          كىنو كۆرمەپسىز غۇ !</div>
      </div>
    </div>

    <!-- 功能 -->
    <div class="rtl q-pa-sm bg-blue-grey-10">
      <div class="bg-blue-grey-2 border-r-8 q-pa-md bg-blue-grey-10">
        <div class="row justify-between q-pb-md border-b-1 q-mb-md Ukij lists-style" v-for="item in lists"
          :key="item.id" @click="onClickFun(item)">
          <div class="text-blue-grey-1 row">
            <div class="width_20 q-ml-sm"><q-img :src="item.icon" /></div>
            <div>{{item.name}}</div>
          </div>
          <div class="width_10"><q-img :src="`${reqUrlData.url.icon_url}jiantog.svg`" /></div>
        </div>
      </div>
    </div>

    <!-- 二维码对话框 -->
    <q-dialog v-model="icon">
      <q-card class="full-width full-width">
        <q-card-section>
          <!-- <q-img class="full-width full-width" :src="`${reqUrlData.url.vipAtushIconImage}qiyat02_w_code.jpg`" /> -->
          <q-img class="full-width full-width" :src="`${reqUrlData.url.vipAtushIconImage}qeyser.jpg`" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- 没开通提示对话框 -->
    <q-dialog v-model="promptDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6 Ukij text-accent">ئەسكەرتىش</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <p class="Ukij rtl">مەزكۈر ئىقتىدار تېخى ئېچىۋتىلمىدى !</p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn class="Ukij" flat label="بولىدۇ" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- 分享转发对话框 -->
    <q-dialog v-model="shareDialog" position="right">
      <q-card>
        <q-card-section>
          <div class="Ukij rtl text-justify line-height-35"> <span>ئوڭ تەرەپ ئۈستى بۇرجەكتىكى ئۈچ چىكىت بەلگىسىنى بېسىپ،
              چىققان تىزىملىكتىن دوستقا</span> <span><q-btn class="e-p-a-5" round color="grey-3" size="10px"
                style="display: inline"><q-img style="width: 20px; height: 20px; display: inline"
                  :src="`${reqUrlData.url.vipAtushIconUrl}wechat_forward.svg`" /></q-btn></span> <span>ياكى چەمبىرەككە</span>
            <span><q-btn class="e-p-a-5" round color="grey-3" size="10px" style="display: inline"><q-img
                  style="width: 20px; height: 20px; display: inline"
                  :src="`${reqUrlData.url.vipAtushIconUrl}pengyq.svg`" /></q-btn></span> <span>ھەمبەھىرلىسڭىز بولىدۇ</span>
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions align="center" class="bg-white text-teal">
          <q-btn class="Ukij full-width full-height" flat label="بولىدۇ بىلدىم" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- 技术支持 -->
    <div class="bg-blue-grey-10 text-center text-grey-6 q-pt-lg q-pb-xl">@科雅特网络科技 提供技术</div>
    <div class="bg-blue-grey-10 text-grey-6 q-pt-lg q-pb-xl"></div>

    <!-- tab -->
    <Tab tab_active="3"></Tab>

  </div>
</template>

<script>
import Tab from '../../components/tab/Tab.vue';
import Refresh from '../../components/Refresh/Refresh.vue';
import { reqLogin, getuserInfo, reqGetFavorites, getUserByUserIdAuth } from '../../request/api.js';
import { ref } from 'vue';
import { reqUrl } from '@/request/url.js';

export default {
  name: 'mine',
  components: {
    Tab,
    Refresh
  },
  data() {
    return {
      reqUrlData: reqUrl,
      head_select: [
        { id: 1, name: 'ساقلىۋالغانلىرىم', icon: `${reqUrl.url.icon_url}collection.svg`},
        { id: 2, name: 'يوقلىما مەركىزى', icon: `${reqUrl.url.icon_url}calendar.svg`},
        { id: 3, name: 'ۋاكالەتچىم', icon: `${reqUrl.url.icon_url}broker.svg`},
        { id: 4, name: 'تەڭشەكلەر', icon: `${reqUrl.url.icon_url}tagxak.svg`}
      ],
      record: [],
      lists: [
        { id: 1, name: 'ئادەملىك مۇلازىمەتچى', icon: `${reqUrl.url.icon_url}setup2.svg`},
        { id: 2, name: 'باشقۇرغۇچى ئۈندىدارى', icon: `${reqUrl.url.icon_url}messg.svg`},
        { id: 3, name: 'كىنوخانىنى ھەمبەھىرلەي', icon: `${reqUrl.url.icon_url}fenxiang.svg`},
      ],
      userInfo: null, // 用户信息
      icon: ref(false),
      promptDialog: false,
      movieDate: true, // 预览骨架显示
      shareDialog: false, // 分享转发对话框
      vipTime: '',
    }
  },
  methods: {
    // 头部选项点击
    onClickHead_select(item) {
      let data;
      switch (item.id) {
        case 1:
          data = { type: 1 }
          this.$router.push({ path: '/favorites', query: data })
          break;
        case 2:
          this.$router.push({ path: '/named' })
          break;
        case 3:
          this.$router.push({path: '/broker'})
          break;
        case 4:
          this.$router.push({ path: '/settings' })
          break;
        default:
          break;
      }
    },

    // 点击电影
    onClickMovie (item, type) {
      let data = {
        type: type,
        id: item.content_id
      }
      this.$router.push({ path: '/detailed', query: data })
    },

    // 点击选项
    onClickFun(item) {
      switch (item.id) {
        case 1:
          this.promptDialog = true
          break;
        case 2:
          this.icon = true;
          break;
        case 3:
          this.shareDialog = true
          break;
        default:
          console.log("默认");
          break;
      }
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

    // 点击更多
    onClickMore () {
      let data = { type: 0 }
      this.$router.push({ path: '/favorites', query: data })
    },

    // 登录请求
    reqLoginFun() {
      reqLogin().then(res => {
        if (res.status == 200) {
          window.location.href = res.data
        }
      }).catch()
    },

    // 判断是否有 code
    isCode() {
      if (window.location.search.includes('code=')) {
        localStorage.setItem('isCode', this.$route.query.code)
        let code = this.$route.query.code
        let data = { code }
        getuserInfo(data).then(res => {
          const userInfo = res.data.data
          const token = res.data.token
          this.userInfo = userInfo
          localStorage.setItem("userInfo", JSON.stringify(userInfo))
          localStorage.setItem("token", token)
        }).catch()
      }
      return localStorage.getItem("isCode") !== null
    },

    // 获取用户信息
    getLocalUserInfo () {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
    },

    // 获取预览
    getFavorites() {
      let type = 0
      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      // let user_id = 1
      let user_id = userInfo.id
      let pageNo = 1
      let pageSize = 3
      let data = {type, user_id, pageNo, pageSize}
      reqGetFavorites(data).then(res => {
        if(res.status == 200) {
          this.record = this.record.concat(res.data.arr)
          this.movieDate = false
        }

      }).catch()
    },

    // 获取用户订单信息
    getUserOrder() {

      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      // let user_id = 1;
      let user_id = userInfo.id;

      let data = { user_id };

      getUserByUserIdAuth(data).then(res => {
        if(res.status == 200) {
          this.vipTime = parseInt(res.data.vipTime)
        }
      }).catch(err => {})
    },
  },
  created() {
    this.scroll_top();
    if (!this.isCode()) this.reqLoginFun();
    this.getLocalUserInfo();
    this.getFavorites();
    this.getUserOrder();
  }
}
</script>

<style scoped>
.top_head {
  border-radius: 8px 8px 0 0;
}
.top_bottom {
  border-radius: 0 0 8px 8px;
}
.title_right {
  width: 5px;
  border-radius: 50px;
}
.box-list {
  margin-left: 5%;
}
.box-list:nth-child(3n) {
  margin-left: 0;
}

.lists-style:nth-child(3n) {
  border: 0;
  padding-bottom: 0;
}

.mine{height: 100vh;}
</style>
